import React from 'react';
import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import dynamic from 'next/dynamic';

import { useTranslation } from 'next-i18next';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import NavigatorLink from 'views/common/components/Logical/NavigatorLink';
import HelpIcon from '@mui/icons-material/Help';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';

import PATHS from 'lib/navigation/paths';
import { FAQS_TYPES_PATHS } from 'lib/utils/CONSTANTS';

const PhonesDialog = dynamic(() => import('views/common/components/Logical/Layout/Footer/NeedHelp/PhonesDialog'));
const ContactUsDialog = dynamic(() => import(
  'views/common/components/Logical/Layout/Footer/NeedHelp/ContactUsDialog'
));

const StyledButton = styled(Button)(({ theme }) => ({
  '&.MuiButton-root': {
    width: '100%',
    flexDirection: 'column',
    color: theme.palette.text.primary,
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
}));

const Root = styled('div')(({ theme }) => ({
  '&.root': {
    backgroundColor: theme.palette.grey[800],
    padding: theme.spacing(2.5),
    color: theme.palette.text.primary,
  },
  '& .grid': {
    textAlign: 'center',
  },
  '& .title': {
    fontSize: '2rem',
    fontWeight: 500,
    marginTop: theme.spacing(1),
    lineHeight: theme.spacing(4),
  },
  '& .icon': {
    fontSize: '2.2rem',
  },
}));

const NeedHelp = () => {
  const { appState, leadCategoryId } = useSelector((state) => state.globalData);
  const { t } = useTranslation();
  const [shouldRenderDialog, setShouldRenderDialog] = React.useState({
    contactUs: false,
    phones: false,
  }); // Only render dialogs into the DOM on demand

  const [isOpen, setOpen] = React.useState(false); // Contact-us dialog
  const [isPhonesOpen, setPhonesOpen] = React.useState(false); // Phones dialog

  const handleContactUsButtonClick = () => {
    setShouldRenderDialog({ contactUs: true });
    setOpen(true);
  };

  const handlePhonesButtonClick = () => {
    setShouldRenderDialog({ phones: true });
    setPhonesOpen(true);
  };

  return (
    <Root className="root">
      <Container maxWidth="xl">
        <Grid container spacing={2}>
          <Grid item lg={3} xs={12} className="grid">
            <Typography component="div" className="title">
              {t('fe_er_common_layout:need_help')}
            </Typography>
          </Grid>
          <Grid item lg={3} xs={4} className="grid">
            <StyledButton
              component={NavigatorLink}
              target="_blank"
              href={PATHS.faqs.withSlug(FAQS_TYPES_PATHS.rental)}
              className="button">
              <HelpIcon className="icon" />
              <Typography variant="button">
                {t('fe_er_common_layout:frequent_questions_mobile')}
              </Typography>
            </StyledButton>
          </Grid>
          <Grid item lg={3} xs={4} className="grid">
            <StyledButton
              onClick={handleContactUsButtonClick}
              className="button">
              <EmailIcon className="icon" />
              <Typography variant="button">
                {t('fe_er_common_layout:email')}
              </Typography>
            </StyledButton>
            {shouldRenderDialog.contactUs && isOpen && (
              <ContactUsDialog
                open={isOpen}
                onClose={() => {
                  setOpen(false);
                  setShouldRenderDialog({
                    ...shouldRenderDialog,
                    contactUs: false,
                  });
                }}
                leadCategoryId={leadCategoryId}
                title={t('fe_er_common_layout:contact_eaglerider')}
                userId={appState.current_user ? appState.current_user.id : null}
              />
            )}
          </Grid>
          <Grid item lg={3} xs={4} className="grid">
            <StyledButton onClick={handlePhonesButtonClick} className="button">
              <PhoneIcon className="icon" />
              <Typography variant="button">
                {t('fe_er_common_layout:phone')}
              </Typography>
            </StyledButton>
            {shouldRenderDialog.phones && isPhonesOpen && (
              <PhonesDialog
                open={isPhonesOpen}
                onClose={() => setPhonesOpen(false)}
                phones={appState.customer_support.eaglerider.phones}
                title={t('fe_er_common_layout:contact_eaglerider')}
              />
            )}
          </Grid>
        </Grid>
      </Container>
    </Root>
  );
};

export default NeedHelp;
